export const FoodTagVeg = require("../assets/images/Vector.png");
export const FoodTagNonVeg = require("../assets/images/NonVegfoodTag.svg");
export const NotWishlisted = require("../assets/images/heart_white.svg");
export const Wishlisted = require("../assets/images/heart-icon.svg");
export const WishlistImg = require("../assets/images/Wishlisted.png");
export const Product1 = require("../assets/images/product7.png");
export const Product2 = require("../assets/images/product8.png");
export const Product3 = require("../assets/images/product9.png");
export const Product4 = require("../assets/images/product10.png");
export const Product5 = require("../assets/images/product11.png");
export const Product6 = require("../assets/images/product12.png");
export const modalCloseIcon = require("../assets/images/modalClose.svg");
export const noProduct = require("../assets/images/no-food.png");
