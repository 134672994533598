// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

// Customizable Area Start
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isNonVeg?: boolean;
  isVeg?: boolean;
  loader?: boolean;
  itemQuantity?: any;
  productList?: Array<any>;
  categoryList?: Array<any>;
  cartList?: Array<any>;
  bannerImgList?: any;
  numberOfProduct?: number;
  isFavorites?: boolean;
  categoryId?: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}
export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getAllProductAPICallId: string;
  getCategoriesAPICallId: string;
  getCartAPICallId: string;
  getBannersApiCallId: string;
  sendDeviceTokenApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    let isShowSearchBar = new Message(
      getName(MessageEnum.ShowSearchBarApiMessage)
    );
    isShowSearchBar.addData(
      getName(MessageEnum.ShowSearchBarApiResponseMessage),
      true
    );
    runEngine.sendMessage(isShowSearchBar.id, isShowSearchBar);
    // Customizable Area End

    this.state = {
      // Customizable Area Start
      loader: false,
      isVeg: false,
      productList: [],
      categoryList: [],
      bannerImgList: [],
      cartList: [],
      itemQuantity: 0,
      numberOfProduct: 10,
      isNonVeg: false,
      isFavorites: false,
      categoryId: 0,
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getBannersImgList();
    this.onFcmRegister();
    this.getCategoryList();
    this.getCartList();
    if (this.state.categoryId > 0) {
      this.getProductList();
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentWillReceiveProps(nextProps: any) {
    this.getProductList();
  }
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    let userData;
    if (typeof window !== "undefined" && localStorage.getItem("userData")) {
      userData = JSON.parse(localStorage.getItem("userData") || "");
    }
    let updateUserInfoMessage = new Message(
      getName(MessageEnum.UserInfoApiMessage)
    );
    updateUserInfoMessage.addData(
      getName(MessageEnum.UserInfoApiResponseMessage),
      userData
    );
    runEngine.sendMessage(updateUserInfoMessage.id, updateUserInfoMessage);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallID = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJSON = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorMessage = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJSON && responseJSON.data) {
        if (apiRequestCallID !== null) {
          //get products
          if (apiRequestCallID === this.getAllProductAPICallId) {
            this.setState({
              loader: false,
              productList: responseJSON.data,
            });
            if (this.state.isFavorites) {
              let result = responseJSON.data?.catalogues?.filter(
                (ele: any, index: number) => {
                  if (ele?.attributes?.wishlisted) {
                    return ele;
                  }
                }
              );
              const finalArray = {
                catalogues: [...result],
                meta: responseJSON.data.meta,
              };
              this.setState({
                productList: finalArray,
              });
            }
          }
          //get banners
          if (apiRequestCallID === this.getBannersApiCallId) {
            this.bannerImgListResponce(responseJSON.data?.banners?.data);
            let bannerListData = new Message(
              getName(MessageEnum.BannerListApiMessage)
            );
            bannerListData.addData(
              getName(MessageEnum.BannerListApiResponseMessage),
              responseJSON.data?.banners?.data
            );
            runEngine.sendMessage(bannerListData.id, bannerListData);
          }
          //get categories
          if (apiRequestCallID === this.getCategoriesAPICallId) {
            this.setState({
              loader: false,
              categoryList: responseJSON.data,
            });
            if (responseJSON.data) {
              this.setState({
                categoryId:
                  responseJSON.data && responseJSON.data[0]?.attributes?.id,
              });
              this.props.history.push(
                `/?category_id=${
                  responseJSON.data && responseJSON.data[0]?.attributes?.id
                }`
              );
            }
          }
          //get cart list
          if (apiRequestCallID === this.getCartAPICallId) {
            this.setState({
              loader: false,
              cartList: responseJSON.data,
            });
            localStorage.setItem("cartId", responseJSON.data?.order?.data?.id);
            localStorage.setItem(
              "cartDataLen",
              responseJSON?.data?.order?.data?.attributes?.order_items?.length
            );
            let cartListUpdateMessage = new Message(
              getName(MessageEnum.UpdateCartListApiMessage)
            );
            cartListUpdateMessage.addData(
              getName(MessageEnum.UpdateCartListApiResponseMessage),
              responseJSON?.data?.order?.data?.attributes?.order_items?.length
            );
            runEngine.sendMessage(
              cartListUpdateMessage.id,
              cartListUpdateMessage
            );
            if (responseJSON.data?.order) {
              const { data } = responseJSON.data?.order;
              localStorage.setItem(
                "isCartHaveItems",
                data?.attributes?.order_items?.length > 0 && true
              );
            }
          }
        }
      }
      if (
        responseJSON &&
        responseJSON.errors &&
        responseJSON.errors.length > 0
      ) {
        if (apiRequestCallID !== null) {
          if (apiRequestCallID === this.getCartAPICallId) {
            if (responseJSON.errors[0].token == "Token has Expired") {
              localStorage.clear();
              this.props?.history?.push({
                pathname: "/login",
                state: { activeTab: "2" },
              });
            }
          }
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area End
  /* START -------------------------------------------------------------- Banner Img List */
  bannerImgListResponce = (apiResData: any) => {
    const bannerDataImg = apiResData
      .filter((banner) => banner?.attributes?.banner_position === 1)
      ?.map((banner) => banner?.attributes?.images);
    let bannerList = [];
    for (let i = 0; i < bannerDataImg?.length; i++) {
      for (let j = 0; j < bannerDataImg[i]?.data?.length; j++) {
        if (bannerDataImg[i]?.data[j]?.attributes) {
          bannerList.push(bannerDataImg[i]?.data[j]?.attributes);
        }
      }
    }
    this.setState({ bannerImgList: bannerList });
  };
  /* END -------------------------------------------------------------- Banner Img List */

  //update quantity
  productsQuantity = (value: any) => {
    // Customizable Area Start
    this.setState({ numberOfProduct: this.state.numberOfProduct + 10 });
    setTimeout(() => {
      this.getProductList();
    }, 300);
    // Customizable Area End
  };

  // get product list
  getProductList = async () => {
    // Customizable Area Start
    this.setState({
      loader: true,
      itemQuantity: 0,
    });
    let apiUrl: any;
    let urlSearch = new URLSearchParams(this.props.history.location.search);
    if (window.location.search === "" || urlSearch.get("q[name]") !== null) {
      if (window.location.search === "") {
        apiUrl = `${configJSON.getAllProductsAPIEndPoint}?per_page=${this.state.numberOfProduct}`;
      } else {
        apiUrl = `${
          configJSON.getSearchBlockAPIEndpoint
        }?q[name]=${urlSearch.get("q[name]")}&per_page=${
          this.state.numberOfProduct
        }`;
      }
    } else {
      apiUrl = `${configJSON.getAllProductsAPIEndPoint}${window.location.search}&per_page=${this.state.numberOfProduct}`;
    }
    if (apiUrl) {
      this.getAllProductAPICallId = await this.apiCall({
        contentType: configJSON.ApiContentType,
        method: configJSON.apiGetMethod,
        endPoint: apiUrl,
      });
    }
    // Customizable Area End
  };

  //get Categories
  getCategoryList = async () => {
    // Customizable Area Start
    this.setState({
      loader: true,
    });

    this.getCategoriesAPICallId = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiGetMethod,
      endPoint: configJSON.getCategoriesAndProductsListAPIEndPoint,
    });
    // Customizable Area End
  };

  //get my banners
  getBannersImgList = async () => {
    // Customizable Area Start
    this.setState({
      loader: true,
    });
    this.getBannersApiCallId = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiGetMethod,
      endPoint: configJSON.getWebBannersAPIEndpoint,
    });
  };

  // get cart list
  getCartList = async () => {
    // Customizable Area Start
    this.setState({
      loader: true,
    });
    this.getCartAPICallId = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiGetMethod,
      endPoint: configJSON.getCartBlockAPIEndPoint,
    });
    // Customizable Area End
  };
  // Customizable Area End

  /* START -------------------------------------------------------------- Firebase access device */
  onFcmRegister = async () => {
    let fcmToken = await localStorage.getItem("fcmToken");
    let data = {
      data: {
        attributes: {
          token: fcmToken,
          platform: "web",
        },
      },
    };
    this.sendDeviceTokenApiCallId = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiPostMethod,
      endPoint: configJSON.sendDeviceTokenAPiEndPoint,
      body: data,
    });
  };

  sendDeviceTokenSuccessCallBack = (res: any) => {
    console.log(
      "@@@ Send Device Token Success CallBack ===================",
      res
    );
  };
  /* END -------------------------------------------------------------- Firebase access device */

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const token = (await localStorage.getItem("token")) || "";
    const header = {
      "Content-Type": contentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
