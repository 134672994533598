import React from "react";
import { withRouter } from "react-router-dom";
import FilterOptionListController, {
  Props,
  configJSON,
} from "./FilterOptionsListController.web";
import "../assets/css/filterOptions.css";

// Customizable Area Start
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { Button } from "reactstrap";
// Customizable Area End

class FilterOptions extends FilterOptionListController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    let sliceCount = 6;
    if (typeof window !== "undefined" && window.innerWidth < 500) {
      sliceCount = 1;
    } else if (typeof window !== "undefined" && window.innerWidth < 900) {
      sliceCount = 3;
    } else if (typeof window !== "undefined" && window.innerWidth < 1100) {
      sliceCount = 4;
    }
    let itemsCategory = this.props.myCategory?.slice(0, sliceCount);
    let itemsMoreCategory = this.props.myCategory?.slice(
      sliceCount,
      this.props.myCategory?.length
    );
    return (
      // @ts-ignore
      <div className="text-center mt-5 mb-5  m-auto" ref={this.wrapperRef}>
        <div className="d-inline-flex pills-button d-inline-flex justify-content-center">
          <div className="filter-option-inner-contnet">
            <div className="p-0 m-0 list-style-none profile-pg-tabs-name d-flex justify-content-center align-items-center">
              {itemsCategory &&
                itemsCategory?.map((category: any, index: any) => (
                  <Button
                    key={category?.id}
                    onClick={() => this.routeToFilter(category?.id, index)}
                    className={
                      this.state.activeCategoryId === category?.id
                        ? "mr-4 category-pills-btn active"
                        : "mr-4 category-pills-btn"
                    }
                  >
                    {category.attributes.name}
                  </Button>
                ))}
            </div>
          </div>
          <div
            className="more-fill-pills"
            onClick={() => this.setState({ more: !this.state.more })}
          >
            <span data-testid="moreItemListID">
              More &nbsp;
              {this.state.more ? (
                <FaCaretUp color="#3fc1cb" />
              ) : (
                <FaCaretDown />
              )}
            </span>
          </div>
          <div className="position-relative">
            <div
              className={this.state.more ? "more-btn-inline" : "more-btn-none"}
            >
              <div style={{ display: "grid" }}>
                {itemsMoreCategory &&
                  itemsMoreCategory?.map((category: any, index: any) => (
                    <>
                      <Button
                        key={category?.id}
                        onClick={() => this.routeToFilter(category?.id, index)}
                        className={
                          this.state.activeCategoryId === category?.id
                            ? "category-pills-btn m-2 active"
                            : "category-pills-btn m-2"
                        }
                      >
                        {category.attributes.name}
                      </Button>
                      <span className="border-bottom"></span>
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  // Customizable Area End
}

// @ts-ignore
export default withRouter(FilterOptions) as React.ComponentClass<{}>;
export { FilterOptions };
// Customizable Area Start
// Customizable Area End