//@ts-ignore
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
// @ts-ignore
import chunk from "lodash/chunk";
//@ts-ignore
const configJSON = require('./config.js');
//content file
//@ts-ignore
import content from '../AppWebContent.js';

export interface Props {
    history: any
};
export interface S {
    isShowFB?: boolean;
    isShowInsta?: boolean;
    isShowGoogle?: boolean;
    isShowTwitter?: boolean;
    contactInfo: any;
    theamData?: any;
    usefulLinks: any;
    loading: boolean;
    isShowYoutube?: boolean;
};
export interface SS { };

export default class FooterController extends BlockComponent<Props, S, SS>{
    getFAQAPICallId: string = "";
    getHelpCenterAPICallId: string = "";
    getBrandSettingAPICallId: string = "";
    getCommonSettingAPICallId: string = "";
    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.state = {
            isShowFB: true,
            isShowInsta: true,
            isShowTwitter: true,
            isShowYoutube: true,
            loading: false,
            usefulLinks: [],
            contactInfo: ''
        }
    };
    routeHelpCenter = (value: any) => {
        if (value !== undefined) {
            let path = '/help-center/' + value;
            setTimeout(() => {
                //@ts-ignore
                this.props.history.push(path)
            }, 1000)
        } else {
            let path = '/help-center';
            this.props?.history?.push(path);
        }
    };

    async componentDidMount() {
        if (localStorage.length == 0) {
            this.getBrandSettings();
            this.getCommonSettings();
        }
        this.getHelpCenter();
        this.getFaqs();
        this.getBrandSettings();
        this.getCommonSettings();
    };

    UNSAFE_componentWillReceiveProps(nextProps: any) {
        if (localStorage.length == 0) {
            this.getBrandSettings();
            this.getCommonSettings();
        }
        this.getHelpCenter();
        this.getFaqs();
        this.getBrandSettings();
        this.getCommonSettings();
    }

    //check API's Reponses function
    async receive(form: string, message: Message) {
        runEngine.debugLog('Message Received', message);
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJSON = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
            if (apiRequestCallID === this.getHelpCenterAPICallId) {
                if (responseJSON && responseJSON.data) {
                    this.setState({
                        loading: false,
                        usefulLinks: responseJSON.data.length > 4 ? chunk(responseJSON.data, 5) : chunk(responseJSON.data, 4)
                    });
                }
            }
            if (apiRequestCallID === this.getFAQAPICallId) {
                if (responseJSON && responseJSON.data && responseJSON.data.faqs && responseJSON.data.faqs.length > 0) {
                    this.setState({
                        loading: false
                    });
                    const copyHelpCenterData = [...this.state.usefulLinks];
                    const isFAQID = copyHelpCenterData.length + 1;
                    const smapleFAQ = {
                        id: isFAQID.toString(),
                        "type": "help_center",
                        "attributes": {
                            "help_center_type": "faq's",
                            "title": "FAQ's",
                            "description": responseJSON.data.faqs
                        }
                    };
                    copyHelpCenterData.push([smapleFAQ]);
                    this.setState({
                        usefulLinks: copyHelpCenterData,
                        loading: false,
                    })
                }
            }
            if (apiRequestCallID === this.getBrandSettingAPICallId) {
                if (responseJSON && responseJSON?.brand_setting) {
                    const { data } = responseJSON?.brand_setting;
                    this.setState({
                        isShowFB: data?.attributes?.footerContent?.facebookSrc ? data?.attributes?.footerContent?.facebookSrc : "",
                        // isShowGoogle: brand_setting?.footerContent?.is_google_login,
                        isShowInsta: data?.attributes?.footerContent?.instagramSrc ? data?.attributes?.footerContent?.instagramSrc : "",
                        isShowTwitter: data?.attributes?.footerContent?.twitterSrc ? data?.attributes?.footerContent?.twitterSrc : "",
                        isShowYoutube: data?.attributes?.footerContent?.youtubeSrc ? data?.attributes?.footerContent?.youtubeSrc : "",
                        theamData: data?.attributes,
                        contactInfo: data?.attributes?.commonTextsContent
                    });
                    let CountryInfo = {
                        'name': data?.attributes?.ExtraFields?.country,
                        'currency_type': data?.attributes?.ExtraFields?.currency_type,
                        'country_code': data?.attributes?.ExtraFields?.country_code
                    };
                    localStorage.setItem('country_info', JSON.stringify(CountryInfo));
                    this.getMyThemes(data?.attributes);
                }
            }
            if (apiRequestCallID === this.getCommonSettingAPICallId) {
                if (responseJSON && responseJSON.data) {
                    //@ts-ignore
                    let commonSettings = {
                        ...responseJSON.data,
                        ...responseJSON.data.settings
                    }
                    delete commonSettings.settings
                    localStorage.setItem("commonSettings", JSON.stringify(commonSettings))
                }
            }
        }
        else {

        }
    };

    //GET FAQ's API call
    getFaqs = () => {
        this.setState({
            loading: true
        });
        setTimeout(() => {
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getFAQAPICallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getFAQAPIEndPoint);

            const headers = {
                'Content-Type': configJSON.validationApiContentType,
            };

            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiGetMethod);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }, 2000);
    };

    //GET HelpCenter API Call
    getHelpCenter = () => {
        this.setState({
            loading: true
        });
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getHelpCenterAPICallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getHelpCenterAPIEndPoint);

        const headers = {
            'Content-Type': configJSON.validationApiContentType,
        };

        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiGetMethod);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    //get BrandSetting
    getBrandSettings = () => {
        this.setState({
            loading: true
        });
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getBrandSettingAPICallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getBrandSettingsAPIEndPoint);

        const headers = {
            'Content-Type': configJSON.validationApiContentType,
        };

        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiGetMethod);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    //get common settings
    getCommonSettings = () => {
        this.setState({
            loading: true
        });
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getCommonSettingAPICallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getCommonSettingsAPIEndPoint);

        const headers = {
            'Content-Type': configJSON.validationApiContentType,
            token: localStorage.getItem("token"),
        };

        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiGetMethod);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    /**************************************** Brand Settings Start ******************************************/
    //@ts-ignore
    getMyThemes = (themeAttributes): any => {
        if (themeAttributes) {
            localStorage.setItem('appThemData', JSON.stringify(themeAttributes));
            let CountryInfo = {
                'name': themeAttributes?.ExtraFields?.country,
                'currency_type': themeAttributes?.ExtraFields?.currency_type,
                'country_code': themeAttributes?.ExtraFields?.country_code
            };
            localStorage.setItem('country_info', JSON.stringify(CountryInfo));
            const root = document.documentElement;
            root.style.setProperty(
                '--button-primary',
                themeAttributes?.buttonsColor?.regularButtonColor
            );
            root.style.setProperty(
                '--button-text-primary',
                themeAttributes?.buttonsColor?.regularTextColor
            );
            root.style.setProperty(
                '--button-primary-hover',
                themeAttributes?.buttonsColor?.hoverButtonColor
            );
            root.style.setProperty(
                '--button-text-primary-hover',
                themeAttributes?.buttonsColor?.hoverTextColor
            );
            root.style.setProperty(
                '--color-RegularActiveText',
                themeAttributes?.mainTextsColor?.activeColorCode
            );
        }
    }
    /**************************************** Brand Settings End  ******************************************/
};
