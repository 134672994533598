//@ts-nocheck
import React, { Component, lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

/** Routes List start */
//Footer  
import Footer from "../../../studio-store-restaurant-components/src/AppWebFooter/Footer.web";

//Header
import Header from "../../../studio-store-restaurant-components/src/AppWebHeader/Header.web";

//LoginScreen
const LoginScreen = lazy(() =>
  import("../../../email-account-login/src/LoginScreen.web")
);

//phoneLogin
const PhoneLogin = lazy(() =>
  import("../../../email-account-login/src/PhoneLogin.web")
);

//Homescreen
import Dashboard from "../../../dashboard/src/Dashboard.web";

//checkoutScreen
const OrderSummary = lazy(() =>
  import("../../../ordersummary/src/OrderSummary.web")
);

//orderSuccess
const OrderSuccess = lazy(() =>
  import("../../../ordersummary/src/OrderSuccess.web")
);

//orderReject
const OrderReject = lazy(() =>
  import("../../../ordersummary/src/OrderReject.web")
);

//ContactUs
const ContactUs = lazy(() => import("../../../contactus/src/ContactUs.web"));

//Help-Centers
const HelpCenterBlock = lazy(() =>
  import("../../../settings/src/HelpCenterBlock.web")
);

//OTP Screen
const OTPConfirm = lazy(() =>
  import("../../../otp-input-confirmation/src/OTPInput.web")
);

//Forgot Password
const ForgotPassword = lazy(() =>
  import("../../../forgot-password/src/ForgotPassword.web")
);

//Profile
const Profile = lazy(() =>
  import("../../../user-profile-basic/src/ProfileIndex.web")
);

//OrderDetailsView
const OrderDetailsView = lazy(() =>
  import("../../../orderdetailview/src/OrderDetailsView.web")
);

//Review
const ReviewManagement = lazy(() =>
  import("../../../settings/src/Reviews.web")
);

//Review
const WishlistWeb = lazy(() =>
  import("../../../user-profile-basic/src/Wishlist.web")
);

/** Routes List End */

/** Private Routes start */
import PrivateRoute from "./PrivateRoute.web";
import ProtectedRoute from "./ProtectedRoute.web";
import Loader from "../../../studio-store-restaurant-components/src/AppWebLoader/Loader.web";
// import WishlistWeb from "../../../user-profile-basic/src/Wishlist.web";
/** Private Roues End */
class WebRoutes extends Component {
  render() {
    const Appthem = JSON.parse(localStorage.getItem("appThemData"));
    const StripePubKey = Appthem?.PaymentKeys?.stripe?.stripe_pub_key
      ? Appthem?.PaymentKeys?.stripe?.stripe_pub_key
      : "";
    const stripePromise = loadStripe(StripePubKey);
    return (
      <div>
        <Elements stripe={stripePromise}>
          <Suspense fallback={<Loader  loading={true}/>}>
            <Header />
            <Switch>
              <PrivateRoute
                path="/"
                exact
                render={(props) => <Dashboard {...props} />}
              />
                <Route  
                  path="/login"
                  exact
                  render={(props) => <LoginScreen {...props} />}
                />
              <Route
                path="/phoneLogin"
                exact
                render={(props) => <PhoneLogin {...props} />}
              />
              <Route
                path="/checkout"
                exact
                render={(props) => <OrderSummary {...props} />}
              />
              <Route
                path="/checkout/success"
                exact
                render={(props) => <OrderSuccess {...props} />}
              />
              <Route
                path="/checkout/reject"
                exact
                render={(props) => <OrderReject {...props} />}
              />
              <Route path="/forgot-password" exact component={ForgotPassword} />
              <Route path="/contact-us" exact component={ContactUs} />
              <Route path="/help-center" exact component={HelpCenterBlock} />
              <Route
                path="/help-center/:slug"
                exact
                component={HelpCenterBlock}
              />
              <Route path="/review" exact component={ReviewManagement} />
              {/* Private Routes */}
              <PrivateRoute
                path="/otpConfirm"
                exact
                render={(props) => <OTPConfirm {...props} />}
              />
              {/********************************** Module-3 *****************************************/}
              <ProtectedRoute
                path="/profile"
                exact
                render={(props) => <Profile {...props} />}
              />
              <ProtectedRoute
                path="/wish-list"
                exact
                render={(props) => <WishlistWeb {...props} />}
              />
              <ProtectedRoute
                path="/profile/myorder/:orderId/:itemId"
                exact
                render={(props) => <OrderDetailsView {...props} />}
              // component={OrderDetailsView}
              />
              <ProtectedRoute
                path="/profile/myorder/:orderId"
                exact
                render={(props) => <OrderDetailsView {...props} />}
              // component={OrderDetailsView}
              />
            </Switch>
            <Footer />
          </Suspense>
        </Elements>
      </div>
    );
  }
}

// @ts-ignore
export default withRouter(WebRoutes);
