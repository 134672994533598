// @ts-nocheck
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import content from "../../studio-store-restaurant-theme/src/AppWebContent";
export const configJSON = require("./config");

// Customizable Area Start
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  updateProductsQuantity: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loader?: boolean;
  itemQuantity?: any;
  showAddToCart?: boolean;
  cartProduct: Array<any>;
  cartTotal: any;
  cartIds: Array<any>;
  formRef: Array<any>;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class FilteritemsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postWishlistApiCallId: string;
  removeWishlistApiCallId: string;
  addToCartApiCallId: string;
  updateCartApiCallId: string;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.UpdateWishlist),
      getName(MessageEnum.UpdateCartListApiMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      loader: false,
      showAddToCart: false,
      isVeg: false,
      cartProduct: [],
      formRef: [],
      cartTotal: 0,
      itemQuantity: 0,
      cartIds: [],
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    // runEngine.debugLog("MY Message Received", message);
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallID = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJSON = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorMessage = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJSON && responseJSON.data) {
        if (apiRequestCallID !== null) {
          this.setState({ itemQuantity: 0 });
          //create wishlist
          if (apiRequestCallID === this.postWishlistApiCallId) {
            window.notify([
              { message: responseJSON?.message, type: "success" },
            ]);
            const wishlist_length = parseInt(
              localStorage.getItem("wishlist_len") ?? "0"
            );
            // @ts-ignore
            localStorage.setItem("wishlist_len", wishlist_length + 1);
            let wishlistUpdateMessage = new Message(
              getName(MessageEnum.UpdateWishlist)
            );
            wishlistUpdateMessage.addData(
              getName(MessageEnum.UpdateWishlistLen),
              wishlist_length + 1
            );
            runEngine.sendMessage(
              wishlistUpdateMessage.id,
              wishlistUpdateMessage
            );
            this.props?.getProducts();
          }
          //delete wishlist
          if (apiRequestCallID === this.removeWishlistApiCallId) {
            window.notify([
              { message: responseJSON?.message, type: "success" },
            ]);
            const wishlist_length = parseInt(
              localStorage.getItem("wishlist_len") ?? "0"
            );
            // @ts-ignore
            localStorage.setItem("wishlist_len", wishlist_length - 1);
            let wishlistUpdateMessage = new Message(
              getName(MessageEnum.UpdateWishlist)
            );
            wishlistUpdateMessage.addData(
              getName(MessageEnum.UpdateWishlistLen),
              wishlist_length - 1
            );
            runEngine.sendMessage(
              wishlistUpdateMessage.id,
              wishlistUpdateMessage
            );
            this.props?.getProducts();
          }
          //add to cart
          if (apiRequestCallID === this.addToCartApiCallId) {
            window.notify([
              { message: "Product has been Added To Cart", type: "success" },
            ]);
            const cartDataLength = parseInt(
              localStorage.getItem("cartDataLen") ?? "0"
            );
            // @ts-ignore
            localStorage.setItem("cartDataLen", cartDataLength + 1);
            let cartListUpdateMessage = new Message(
              getName(MessageEnum.UpdateCartListApiMessage)
            );
            cartListUpdateMessage.addData(
              getName(MessageEnum.UpdateCartListApiResponseMessage),
              cartDataLength + 1
            );
            runEngine.sendMessage(
              cartListUpdateMessage.id,
              cartListUpdateMessage
            );
            this.props?.getProducts();
            this.props?.getCarts();
          }
          //update cart
          if (apiRequestCallID === this.updateCartApiCallId) {
            window.notify([
              { message: "Cart has been Updated.", type: "success" },
            ]);
            this.props?.getProducts();
            this.props?.getCarts();
          }
        }
      }
      if (errorMessage) {
        this.setState({ loader: false });
        // window.notify([
        //     { type: "danger", message: errorMessage || content.someThingWent },
        // ]);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  //Add To Wishlist
  addtoWishlist = (catalogue_id: any): boolean => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const httpBody = {
      catalogue_id: catalogue_id,
    };

    this.postWishlistApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postWishlistAPIEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
    // Customizable Area End
  };

  //Remove from Wishlist
  removeWishlist = (catalogue_id: any): boolean => {
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("token"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeWishlistApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.delWishlistAPIEndpoint + `${catalogue_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiDeleteMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  //Toggle Add To Cart modal
  toggleAddToCartModal = (catalogue: any): boolean => {
    this.setState({
      showAddToCart: !this.state.showAddToCart,
      cartProduct: catalogue,
      cartTotal: 0,
      cartIds: [],
    });
  };

  //Calculate Cart Total & populate subs ids
  calculateCartTotal = (catalogue: any): boolean => {
    let productPrice = this.state.cartProduct?.attributes?.on_sale
      ? // @ts-ignore
        this.state.cartProduct?.attributes?.sale_price
      : // @ts-ignore
        this.state.cartProduct?.attributes?.price;
    let sampleArray = [];
    let totalSum = productPrice;
    let ids = [];
    for (var key in catalogue) {
      if (catalogue.hasOwnProperty(key)) {
        var val = catalogue[key];
        sampleArray.push(val);
      }
    }
    sampleArray.map((content) => {
      if (typeof content === "string") {
        const [sum, id] = content.split(",");
        // totalSum += parseFloat(sum);
        totalSum = parseInt(totalSum) + parseInt(sum);
        ids.push({ catalogue_attribute_value_id: id });
      } else if (typeof content === "object") {
        content.map((myData, ind) => {
          const [sum, id] = myData.split(",");
          // totalSum += parseFloat(sum);
          totalSum = parseInt(totalSum) + parseInt(sum);
          ids.push({ catalogue_attribute_value_id: id });
        });
      }
    });
    this.setState({
      cartTotal: parseFloat(totalSum),
      cartIds: ids,
    });
  };

  //Add To Cart
  addToCart = (type: any): boolean => {
    this.setState({ showAddToCart: false });
    const header = {
      "Content-Type": configJSON.ApiContentType,
      token: localStorage.getItem("token"),
    };
    let httpBody;
    if (type === "Modal") {
      httpBody = {
        catalogue_id: parseInt(this.state.cartProduct?.id),
        quantity: 1,
        order_item_options_attributes: this.state.cartIds,
      };
    } else {
      httpBody = {
        catalogue_id: parseInt(type?.id),
        quantity: 1,
      };
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.addToCartApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.orderBlockAPIEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  //Update Cart
  updateCartQuantity = (product: any, action: string): boolean => {
    if (action === "Add") {
      this.setState({
        itemQuantity:
          product?.attributes?.cart_quantity + this.state.itemQuantity + 1,
      });
    } else if (action === "Subtract") {
      this.setState({
        itemQuantity:
          product?.attributes?.cart_quantity + this.state.itemQuantity - 1,
      });
    }

    setTimeout(() => {
      const header = {
        "Content-Type": configJSON.ApiContentType,
        token: localStorage.getItem("token"),
      };

      const httpBody = {
        catalogue_id: parseInt(product?.id),
        quantity: this.state.itemQuantity,
      };

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.updateCartApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.orderBlockAPIEndpoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiPostMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      return true;
    }, 200);
  };

  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End
