// @ts-ignore
import React from "react";
import { withRouter } from "react-router-dom";

// Customizable Area Start
import { Carousel } from "react-responsive-carousel";
import { BiChevronRightCircle, BiChevronLeftCircle } from "react-icons/bi";
import { Container } from "reactstrap";
import FilterOptions from "../../filteritems/src/FilterOptions.web";
import FilterProducts from "../../filteritems/src/Filterproducts.web";
import ShoppingCart from "../../shoppingcart/src/ShoppingCart.web";
import Loader from "../../studio-store-restaurant-components/src/AppWebLoader/Loader.web";

import "../assets/css/index.scoped.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import BannerSkeltonLoader from "../../studio-store-restaurant-components/src/SkeletonLoader/BannerSkeltonLoader";
import CategorySkeltonLoader from "../../studio-store-restaurant-components/src/SkeletonLoader/CategorySkeltonLoader";
import Sticky from "react-stickynode";
// Customizable Area End

import DashboardController, { Props } from "./DashboardController.web";

class Dashboard extends DashboardController {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    let myCategory = { myCategory: this.state.categoryList };
    return (
      <>
        <section className="dashboard-carousel-section">
          <div className="banner-section">
            {this.state.bannerImgList?.length > 0 ? (
              <Carousel
                showStatus={false}
                showArrows={false}
                swipeable={true}
                autoPlay={true}
                showIndicators={
                  this.state.bannerImgList?.length > 1 ? true : false
                }
                interval={5000}
                infiniteLoop={true}
                className="carousel"
                showThumbs={false}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                  hasPrev && (
                    <BiChevronLeftCircle
                      onClick={onClickHandler}
                      className="left-arrow"
                    />
                  )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                  hasNext && (
                    <BiChevronRightCircle
                      onClick={onClickHandler}
                      className="right-arrow"
                    />
                  )
                }
              >
                {this.state.bannerImgList?.map((banner: any, index: number) => (
                  <div
                    key={index}
                    onClick={() => {
                      banner?.url_link &&
                        window.location.replace(banner?.url_link);
                    }}
                    className="banner-img"
                  >
                    <img
                      src={banner?.url}
                      style={
                        banner?.url_link
                          ? { cursor: "pointer" }
                          : { cursor: "default" }
                      }
                      className="bg-banner"
                    />
                  </div>
                ))}
              </Carousel>
            ) : (
              <BannerSkeltonLoader />
            )}
          </div>
          {this.state.categoryList?.length ? (
            <FilterOptions {...myCategory} />
          ) : (
            <div className="profile-pg-inner-wrap profile-inner-tab-content">
              <CategorySkeltonLoader />
            </div>
          )}
          <>
            <div className="h-100" id="sticky-container">
              <div className="row">
                <div className="col-md-7 col-sm-7 col">
                  <div className="pp-filter-products">
                    <FilterProducts
                      // @ts-ignore
                      myCategory={this.state.categoryList}
                      vegCounter={this.state.isVeg}
                      nonVegCounter={this.state.isNonVeg}
                      isFavorites={this.state.isFavorites}
                      getProducts={this.getProductList}
                      getCarts={this.getCartList}
                      updateProductsQuantity={this.productsQuantity}
                      myLoader={this.state.loader}
                      myProductsList={this.state.productList}
                      loadingStamp={this.state.loader}
                    />
                  </div>
                </div>
                <div className="col-md-5 col-sm-5 col">
                  <div className="pp-filter-products">
                    {/* <Sticky bottomBoundary="#sticky-container"> */}
                    <ShoppingCart
                      // @ts-ignore
                      getProducts={this.getProductList}
                      getCarts={this.getCartList}
                      myCartList={this.state.cartList}
                    />
                    {/* </Sticky> */}
                  </div>
                </div>
              </div>
            </div>
          </>
        </section>
      </>
    );
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}
// @ts-ignore
export default withRouter(Dashboard) as React.ComponentClass<{}>;
export { Dashboard };
// Customizable Area Start
// Customizable Area End
